import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  ListItemText,
  Checkbox,
  Menu,
  IconButton,
  TableCell,
  Typography,
  TableFooter,
  Tooltip,
  Table,
  TableBody,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Fade,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextsmsIcon from "@mui/icons-material/Textsms";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import JsonData from "../client/data.json";
import ViewModal from "../client/viewModal";
import InfoIcon from "@mui/icons-material/Info";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InventoryIcon from "@mui/icons-material/Inventory";
import TuneIcon from "@mui/icons-material/Tune";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import MarkerImage from "../../assets/images/marker.png";
import "leaflet/dist/leaflet.css";
import Client from "../client/client";
import { useToken } from "../../context/TokenContext";
import dotnetApiClient from "../../config/dotnetApiClient";
import EmptyState from "../EmptyState/EmptyState";
import FooterComponent from "../footer";
import ChatDialog from "./ChatDialog";
import { formatDateTime, logErrorFunction, removeSpaces } from "../../helper/service";
import AddClientModal from "../client/AddClientModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ClientType {
  id: number;
  name: string;
  email: string;
  phone: string;
  progressPercentage: number;
  lastLoginDtUtc: string;
  unreadMessageCount?: number;
}

interface FilterableColumnTableProps {
  clients: Client[];
  setShowAddClientModal: any;
  handleAddClient: any;
  fetchDataFromAPI: any;
  setApiCalled: any;
  showAddClientModal: any;
}

export const FilterableColumnTable: React.FC<FilterableColumnTableProps> = ({
  clients,
  setShowAddClientModal,
  handleAddClient,
  fetchDataFromAPI,
  setApiCalled,
  showAddClientModal,
}) => {
  const navigate = useNavigate();
  const tempDataJson: any = JSON.parse(localStorage.getItem("dataJson") || "{}");
  const [setDataJsonTemp] = useState<any>(tempDataJson);
  const [uiUserData, setUiUserData] = useState<ClientType[]>([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedClientDetails, setSelectedClientDetails] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabValue, setTabValue] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [markers] = useState<any[]>([{ markers: [], name: "" }]);
  const [clientToEdit, setClientToEdit] = useState<Client | null>(null);
  const { token } = useToken();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<number | null>(null);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<any>();
  const processedClients = useRef<Set<number>>(new Set());
  const [searchTerm, setSearchTerm] = useState("");
  const [isClientDeleting, setIsClientDeleting] = useState(false);

  useEffect(() => {
    const clientsList = clients.map((client) => ({
      id: client.id,
      name: client.name,
      email: client.email,
      phone: client.phoneNumber,
      progressPercentage: client.progressPercentage,
      lastLoginDtUtc: client.lastLoginDtUtc,
      uiFlowJsonID: client.uiFlowJsonID,
      unreadMessageCount: 0, // Initialize unreadMessageCount to 0
      caseName: client?.formsForCaseTypes?.[0]?.caseName,
      formsForCaseTypes: client?.formsForCaseTypes,
    }));
    setUiUserData(clientsList);
  }, [clients]);

  useEffect(() => {
    setPage(0);
    const clientsList = clients
      .map((client) => ({
        id: client.id,
        name: client.name,
        email: client.email,
        phone: client.phoneNumber,
        progressPercentage: client.progressPercentage,
        lastLoginDtUtc: client.lastLoginDtUtc,
        uiFlowJsonID: client.uiFlowJsonID,
        unreadMessageCount: 0, // Initialize unreadMessageCount to 0
        caseName: client?.formsForCaseTypes?.[0]?.caseName,
        formsForCaseTypes: client?.formsForCaseTypes,
      }))
      .filter((c: any) => (c.name + c.email).toLowerCase().includes(searchTerm.toLowerCase()));
    setUiUserData(clientsList);
  }, [searchTerm]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const commonOptions = {
    sort: true,
  };

  const generateColumn = (name: string, label: string, options = {}, sortable: string) => ({
    name,
    label,
    options: {
      customHeadRender: ({ index, ...column }: any) => {
        return (
          <TableCell key={index} sx={{ py: "8px" }} onClick={() => handleSort(name)}>
            <IconButton>
              <InfoIcon />
            </IconButton>
            <Typography sx={{ fontWeight: "700", color: "black" }}>{label}</Typography>
          </TableCell>
        );
      },
      ...options,
    },
    sortable,
  });

  let columns: any = JsonData.columns
    .map((name: any) => {
      if (name?.flag) {
        return generateColumn(
          name?.value,
          name?.title,
          {
            ...commonOptions,
          },
          (name.sortable = "asc")
        );
      }
      return null;
    })
    .filter((column: any) => column !== null);

  const handleViewCurrUser = (customer: any) => {
    setSelectedClientDetails(customer);
    setShowViewModal(!showViewModal);
  };

  const handleUpdateClient = (updatedClient: Client) => {
    setUiUserData((prevUiUserData) =>
      prevUiUserData.map((u) =>
        u.id === updatedClient.id
          ? {
              id: updatedClient.id,
              name: updatedClient.name,
              email: updatedClient.email,
              phone: updatedClient.phoneNumber,
              progressPercentage: updatedClient.progressPercentage,
              lastLoginDtUtc: updatedClient.lastLoginDtUtc,
              uiFlowJsonID: updatedClient.uiFlowJsonID,
            }
          : u
      )
    );
  };

  const handleEditCurrUser = (user: any) => {
    const client: Client = {
      id: user.id,
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber ?? user.phone,
      progressPercentage: user.progressPercentage,
      caseType: "",
      jsonId: "",
      formName: "",
      formVersion: "",
      lastLoginDtUtc: user.lastLoginDtUtc,
      uiFlowJsonID: user.uiFlowJsonID,
      formsForCaseTypes: user.formsForCaseTypes,
    };
    setClientToEdit(client);
    // setShowEditModal(true);
    setShowAddClientModal(true);
  };

  const handleSort = (field: string) => {
    const newSortOrder: any = sortOrder === "asc" ? "desc" : "asc";
    const sortedData: any = [...uiUserData];

    sortedData.sort((a: any, b: any) => {
      if (newSortOrder === "asc") {
        if (field === "progress") {
          return a["progressPercentage"] - b["progressPercentage"];
        } else {
          return a[field]?.localeCompare(b[field]);
        }
      } else {
        if (field === "progress") {
          return b["progressPercentage"] - a["progressPercentage"];
        } else {
          return b[field]?.localeCompare(a[field]);
        }
      }
    });

    setSortOrder(newSortOrder);
    setUiUserData(sortedData);
  };

  const handleChange = (event: any, selectedData: any) => {
    let tempFlag: boolean = !selectedData?.flag;
    let tempJsonData = [...JsonData.columns];
    tempJsonData = tempJsonData.map((columnData: any) => {
      if (selectedData?.value === columnData?.value) {
        return {
          ...columnData,
          flag: tempFlag,
        };
      }
      return columnData;
    });

    setDataJsonTemp(tempJsonData);
    localStorage.setItem("dataJson", JSON.stringify(tempJsonData));
  };

  const TablePaginationActions = (props: any) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event: any) => {
      onPageChange(event, 0);
    };
    const handleBackButtonClick = (event: any) => {
      onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event: any) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: any) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const handleClickOpenDeleteDialog = (clientId: number) => {
    setClientToDelete(clientId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setClientToDelete(null);
  };

  const handleDeleteClient = async () => {
    if (clientToDelete === null) return;
    setIsClientDeleting(true);
    try {
      const axiosInstance = dotnetApiClient();
      const response = await axiosInstance.delete(`/clients/${clientToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 204 || response.status === 200) {
        setUiUserData((prevUiUserData) =>
          prevUiUserData.filter((client) => client.id !== clientToDelete)
        );
      } else {
        logErrorFunction("Failed to delete client");
      }
    } catch (error: any) {
      logErrorFunction("Error deleting client:" + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setOpenDeleteDialog(false);
      setClientToDelete(null);
      setIsClientDeleting(false);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEmulate = (client: ClientType) => {
    navigate("/iframe", {
      state: { role: "lawyer", token: token, user: client },
    });
  };

  const renderName = (customerName: string | undefined) => {
    if (!customerName) return "";
    const temp = customerName.split(" ");
    if (temp.length < 2) return customerName.charAt(0);
    const finalName = temp[0].charAt(0) + temp[1].charAt(0);
    return finalName;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uiUserData?.length) : 0;

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  var markerIcon = L.icon({
    iconUrl: MarkerImage,
    iconSize: [60, 60],
    iconAnchor: [60, 60],
    popupAnchor: [10, -56],
  });

  const handleChat = (customer: any) => {
    setShowChatModal(true);
    setCurrentClient(customer);
  };
  ////// Chat start ///
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({}); // Loading state for each user

  useEffect(() => {
    if (uiUserData.length === 0) return;

    const processClients = async () => {
      for (const clientData of uiUserData) {
        // Check if the client has already been processed
        if (!processedClients.current.has(clientData.id)) {
          try {
            // Set loading to true for this client
            setLoading((prevLoading) => ({
              ...prevLoading,
              [clientData.id]: true,
            }));
            // Mark the client as processed immediately to prevent duplicate processing
            processedClients.current.add(clientData.id);
            // Wait for both API calls to complete and get the unread message count
            const unreadMessageCount = await callApisForClient(clientData);

            // Update the uiUserData with the unreadMessageCount for the processed client
            setUiUserData((prevState) =>
              prevState.map((user) =>
                user.id === clientData.id ? { ...user, unreadMessageCount } : user
              )
            );
            // Set loading to false for this client after processing
            setLoading((prevLoading) => ({
              ...prevLoading,
              [clientData.id]: false,
            }));
          } catch (err) {
            console.error(err);
            // Set loading to false in case of an error
            setLoading((prevLoading) => ({
              ...prevLoading,
              [clientData.id]: false,
            }));
          }
        }
      }
    };

    processClients();
  }, [uiUserData]);

  // Function to call both APIs using Promise.all and return the unreadMessageCount
  const callApisForClient = async (clientData: any) => {
    try {
      if (clientData?.id) {
        const requestData = { withUserId: clientData?.id };
        // Call both APIs in parallel
        const [chatResponse, messageInfoResponse] = await Promise.all([
          dotnetApiClient().post("/chat", requestData), // Chat API call
          dotnetApiClient().post("/chat/message/info", requestData), // Message Info API call
        ]);
        // Extract unreadMessageCount from the messageInfoResponse
        const unreadMessageCount = messageInfoResponse.data?.unreadMessageCount;
        return unreadMessageCount;
      }
    } catch (error: any) {
      logErrorFunction("Error fetching data: " + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
      throw error; // Rethrow the error to handle in useEffect
    }
  };

  const updateUnreadCount = (newUnreadCount: number) => {
    setUiUserData((prevState) =>
      prevState.map((user) =>
        user.id === currentClient?.id ? { ...user, unreadMessageCount: newUnreadCount } : user
      )
    );
  };
  //chat end //

  return (
    <>
      <Box>
        <Box
          className="dashboard filterableColumnTable"
          sx={{
            padding: { md: "20px", xs: "10px" },
            height: "calc(100vh - 121px)",
            overflowY: "auto",
          }}
        >
          <TextField
            id="search_by_email_or_name"
            placeholder="Search client by name or email"
            variant="outlined"
            fullWidth
            style={{ marginTop: 20, marginLeft: "auto", maxWidth: 400, display: "flex" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box className="inner_filterableColumnTable" sx={{ height: "800px", overflowY: "auto" }}>
            <CustomTabPanel value={tabValue} index={0}>
              <Box className="filterTableContainer">
                <TableContainer className="client_table_container">
                  {uiUserData.length === 0 ? (
                    <Box className="empty_state" sx={{ textAlign: "center", marginTop: 4 }}>
                      <EmptyState />
                    </Box>
                  ) : (
                    <Table className="client_table">
                      <TableHead className="client_table_head">
                        <TableRow className="client_table_row">
                          {columns?.map((column: any, ind: any) => (
                            <TableCell
                              className="client_table_cell"
                              key={ind}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#757575",
                                cursor: "pointer",
                                py: "8px",
                              }}
                              onClick={(e: any) => {
                                if (
                                  column?.name === "name" ||
                                  column?.name === "email" ||
                                  column?.name === "progress"
                                ) {
                                  handleSort(column?.name);
                                }
                              }}
                            >
                              <Box
                                className="client_table_icons"
                                display="flex"
                                alignItems="center"
                              >
                                <span className="customTableHeader">
                                  {column?.name === "id" ? (
                                    <Grid3x3Icon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "name" ? (
                                    <PeopleIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "email" ? (
                                    <EmailIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "progress" ? (
                                    <DonutLargeIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "phone" ? (
                                    <LocalPhoneIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "lastLogin" ? (
                                    <CalendarMonthIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "caseType" ? (
                                    <InventoryIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : column?.name === "action" ? (
                                    <TuneIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  ) : null}
                                </span>
                                <span className="client_table_icon_label">{column?.label}</span>
                                {(column?.name === "name" ||
                                  column?.name === "email" ||
                                  column?.name === "progress") && (
                                  <span className="swapvert_icon">
                                    <SwapVertIcon sx={{ mr: 1, fontSize: "26px" }} />
                                  </span>
                                )}
                              </Box>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="client_table_body">
                        {(rowsPerPage > 0
                          ? uiUserData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : uiUserData
                        ).map((customer: any) => (
                          <TableRow
                            key={`${customer.id}_${customer.caseName}`}
                            id={customer.id}
                            className={customer?.email}
                          >
                            {columns.map((column: any) =>
                              column.name === "name" ? (
                                <TableCell
                                  className="client_name"
                                  key={column.name}
                                  sx={{ py: "8px" }}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div
                                      className="td_container"
                                      id={removeSpaces(`${customer[column.name]}_${customer?.caseName}_name`)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          display: "inline-flex",
                                          borderRadius: "50px",
                                          background: customer?.backgroundColor,
                                          color: "black",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginRight: "5px",
                                          fontWeight: "700",
                                          fontSize: "14px",
                                          backgroundColor: "#FFC0CB",
                                        }}
                                      >
                                        {renderName(customer[column.name])}
                                      </span>
                                      <span id={removeSpaces(`${customer[column.name]}_${customer.caseName}`)}>
                                        {customer[column.name]}
                                      </span>
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : column.name === "progress" ? (
                                <TableCell
                                  className="client_progress"
                                  key={column.progressPercentage}
                                  sx={{ py: "8px" }}
                                >
                                  <div
                                    id={removeSpaces(`${customer?.id}_${customer?.caseName}_progress`)}
                                  >
                                    {customer?.progressPercentage || 0}%
                                  </div>
                                </TableCell>
                              ) : column.name === "email" ? (
                                <TableCell
                                  className="client_email"
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <div
                                    className="message_view"
                                    id={removeSpaces(`${customer?.id}_${customer?.caseName}_email`)}
                                  >
                                    <Tooltip title={customer[column.name]}>
                                      <div
                                        id={removeSpaces(`${customer[column.name]}_${customer?.caseName}`)}
                                        className="td_container message_container"
                                      >
                                        {customer[column.name]}
                                      </div>
                                    </Tooltip>
                                    <IconButton
                                      onClick={() => handleChat(customer)}
                                      className="unread_count_container"
                                    >
                                      {loading[customer.id] ? (
                                        <CircularProgress disableShrink size="20px" value={25} />
                                      ) : (
                                        <TextsmsIcon style={{ color: "#757575" }} />
                                      )}
                                      {customer?.unreadMessageCount > 0 && (
                                        <p className="unread_count_badge">
                                          {customer?.unreadMessageCount}
                                        </p>
                                      )}
                                    </IconButton>
                                  </div>
                                </TableCell>
                              ) : column.name === "caseType" ? (
                                <TableCell
                                  className="client_caseType"
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div
                                      className="td_container"
                                      id={removeSpaces(`${customer?.id}_${customer?.caseName}_caseType`)}
                                    >
                                      {customer?.caseName}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : column.name === "lastLogin" ? (
                                <TableCell
                                  className="client_lastLogin"
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div
                                      className="td_container"
                                      id={removeSpaces(`${customer?.id}_${customer?.caseName}_lastLogin`)}
                                    >
                                      {customer?.lastLoginDtUtc
                                        ? formatDateTime(customer?.lastLoginDtUtc)
                                        : "Not signed in yet"}
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : column.name === "action" ? (
                                <TableCell
                                  className="client_action"
                                  key={column.name}
                                  sx={{ cursor: "pointer", py: "8px" }}
                                >
                                  <Tooltip title={customer[column.name]}>
                                    <div>
                                      <Button
                                        id="client_view_btn"
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() => handleViewCurrUser(customer)}
                                      >
                                        View
                                      </Button>
                                      <Button
                                        id="client_edit_btn"
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() => handleEditCurrUser(customer)}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        id="client_delete_btn"
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() => handleClickOpenDeleteDialog(customer.id)}
                                      >
                                        Delete
                                      </Button>
                                      <Button
                                        id="client_emulate_btn"
                                        variant="outlined"
                                        sx={{
                                          width: "98px",
                                          background: "#eff6ff",
                                        }}
                                        onClick={() => handleEmulate(customer)}
                                      >
                                        Emulate
                                      </Button>
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell
                                  className={column.name}
                                  key={column.name}
                                  sx={{ py: "8px" }}
                                >
                                  <div className="td_container">{customer[column.name]}</div>
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter className="client_table_footer">
                        <TableRow className="client_table_footer_row">
                          <TablePagination
                            className="client_table_pagination"
                            rowsPerPageOptions={[10, 25, 50]}
                            colSpan={columns.length}
                            count={uiUserData?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                              select: {
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )}
                </TableContainer>
                <FooterComponent />
                {/* <ChatComponent /> */}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <div className="container max-w-full h-[600px]">
                <MapContainer
                  center={markers[0]?.markers}
                  zoom={8}
                  scrollWheelZoom={true}
                  className="h-[70vh]"
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {markers?.map((data: any, index: number) => (
                    <Marker key={index} position={data?.markers} icon={markerIcon}>
                      <Popup>{`${data?.name} `}</Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </div>
            </CustomTabPanel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {JsonData.columns.map((name: any) =>
                name?.title === "Action" ? null : (
                  <MenuItem
                    disabled={name?.title === "Name"}
                    key={name?.title}
                    value={name?.title}
                    onClick={(e: any) => handleChange(e, name)}
                  >
                    <Checkbox checked={name?.flag} />
                    <ListItemText primary={name?.title} />
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
        </Box>
      </Box>

      {/* Delete confirmation modal */}
      <Dialog
        className="modal_delete_confirmation"
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          sx: {
            borderRadius: 3,
            padding: 2,
            width: "400px",
            maxWidth: "100%",
          },
        }}
        TransitionComponent={Fade}
        transitionDuration={300}
      >
        <DialogTitle
          className="modal_delete_title"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "error.main",
          }}
        >
          <WarningIcon sx={{ mr: 1 }} />
          Confirm Deletion
        </DialogTitle>
        <DialogContent className="modal_delete_content">
          <DialogContentText
            className="modal_delete_content_title"
            sx={{ color: "text.secondary", mb: 2 }}
          >
            Are you sure you want to delete this record? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="modal_delete_actions"
          sx={{ justifyContent: "space-between", paddingX: 3 }}
        >
          <Button
            className="modal_delete_cancel_btn"
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            color="inherit"
            sx={{
              color: "text.secondary",
              borderColor: "grey.500",
              "&:hover": {
                borderColor: "grey.700",
                backgroundColor: "grey.100",
              },
            }}
          >
            No
          </Button>
          <Button
            id="modal_delete_confirm_btn"
            onClick={handleDeleteClient}
            variant="contained"
            color="error"
            disabled={isClientDeleting}
            startIcon={isClientDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
            sx={{
              "&:hover": {
                backgroundColor: "error.dark",
              },
            }}
          >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* View modal */}
      {showViewModal && (
        <ViewModal
          isViewPopup={showViewModal}
          setIsView={setShowViewModal}
          userId={selectedClientDetails?.id}
          selectedClientDetails={selectedClientDetails}
        />
      )}

      {/* Add client modal */}
      {showAddClientModal && (
        <AddClientModal
          client={clientToEdit}
          open={showAddClientModal}
          onClose={() => {
            setShowAddClientModal(false);
            setClientToEdit(null);
          }}
          onAddClient={handleAddClient}
          fetchDataFromAPI={fetchDataFromAPI}
          setApiCalled={setApiCalled}
        />
      )}

      {/* Chat modal */}
      {showChatModal && (
        <ChatDialog
          showChatModal
          onClose={() => setShowChatModal(!showChatModal)}
          currentClient={currentClient}
          setUnreadCount={(newUnreadCount) => updateUnreadCount(newUnreadCount)}
        />
      )}
    </>
  );
};

export default FilterableColumnTable;
