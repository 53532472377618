import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
} from "../slices/notificationSlice";
import {
  setAllPromptsData,
  setGeneratedValueBasedPrompts,
} from "../slices/documentSlice";
import dotnetApiClient from "../../config/dotnetApiClient";

export const getAllPromptsData: any = createAsyncThunk(
  "getAllPromptsData",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`intelligence/prompts`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllPromptsData(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const generateByPromptsId: any = createAsyncThunk(
  "generateByPromptsId",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `/intelligence/users/${_request.userId}/prompts/${_request.promptId}/generate`
      );
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(
          notificationSuccess(
            "Client value based intelligence generated successfully"
          )
        );
        dispatch(setGeneratedValueBasedPrompts(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
      return response;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
export const generateAllByPromptsId: any = createAsyncThunk(
  "generateByPromptsId",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `/intelligence/users/${_request.userId}/prompts/generate`
      );
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(
          notificationSuccess(
            "All client value based intelligence generated successfully"
          )
        );
        dispatch(setGeneratedValueBasedPrompts(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
      return response;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
