import moment from "moment";

const hostname = window.CURRENT_HOST;

console.log("hostname ====> ", hostname);

export function getApiUrl() {
  return window.API_URL;
}

export function getFrontBaseUrl() {
  if (hostname.includes("localhost")) {
    return "http://localhost:3000/home/";
  } else if (hostname.includes("-dev")) {
    return "https://my-dev.usapath.com/home/";
  } else {
    return "https://my.usapath.com/home/";
  }
}

//Convert into camelcase
export function toCamelCase(str: any) {
  if (!str) {
    return "";
  }
  return str.toLowerCase().replace(/\b\w/g, (s: any) => s.toUpperCase());
}

//Convert into camelcase
export function toUpperCase(str: any) {
  if (!str) {
    return "";
  }
  return str.toUpperCase();
}

export function anonymousUser() {
  let token = localStorage.getItem("accessToken");

  if (token) {
    return false;
  } else {
    return true;
  }
}

// Generate a random GUID
export function generateGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// To log the responses
export function logFunction(loggedData: any) {
  console.log("Logging data for you: ", loggedData);
}

// To log the responses error
export function logErrorFunction(loggedData: any) {
  console.error("Logging error data for you: ", loggedData);
}

// date-time format
export const formatDateTime = (date: string, format = "YYYY-MM-DD HH:mm A") => {
  return moment(date).format(format);
};

export function removeSpaces(text:string) {
  return text.replace(/\s+/g, '');
}
